/* THE FOUR STEPS TO BEING AN AWESOME UI ENGINEER (AND CO-CODER)
1. Try to keep custom styles to a minimum.
2. Be aware that these styles will affect your entire application.
3. Namespace your own custom styles with something
related to your app, for example, .spark-classname or .apollo-classname.
4. Use Canvas global variables for color, spacing and more.
They are CSS variables that are already globally available in the browser.
The full list is located here: https://canvas.prod.target.com/design-tokens. */

.praxcss-height100vh {
  height: 100vh;
}

.praxcss-avatarButton {
  border: 0;
  padding: 0;
  border-radius: var(--canvas-border-radius--expanded);
}

.headerTitle {
  color: white !important;
}

.C-DatePicker__Card {
  z-index: 10
}

.cpm-mobile-root {
  position: relative;
}


.cpm-mobile-root-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 16px;
  height: 100vh
}




.cpm-desktop-root-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  margin: 16px;
  height: 100vh;
  max-width:768px;
  min-width:768px
}


.ag-theme-alpine {
  // --ag-foreground-color: rgb(126, 46, 132);
  // --ag-body-background-color:var(--canvas-background-color--app);
  // --ag-header-foreground-color: rgb(204, 245, 172);
  // --ag-header-background-color: rgb(209, 64, 129);
  //  --ag-odd-row-background-color: var(--canvas-background-color--app);
  //  --ag-even-row-background-color: var(--canvas-background-color--app);
  // --ag-header-column-resize-handle-color: rgb(126, 46, 132);

  // --ag-font-size: 17px;
  // --ag-font-family: monospace;
}

.ag-cell {
  background-color: var(--canvas-background-color--app);
}


.ag-theme-alpine .ag-row {
  border-bottom: none;
}

.ag-grid-loading-card-1 {
  height: 100px;
  display: flex;
  align-items: center
}

.ag-grid-cell-card-container {
  display:flex;
  flex-direction: column;
  gap: 16px;
}

.card-field-content {
  display: block;
  line-height: 1rem;
  font-weight:bold
}


.cpm-mobile-root .C-Toaster.\--location-bottom {
  right:0px;
  // left: 50%;
  // transform: translateX(-50%);
  left: 0;
  bottom: 0;
  margin-left: 6%;
}

.cpm-mobile-root .C-Toaster__Toast {
   right:0px;
  max-width: max-content;
  width: 100%;
}

.page-header-container {
  display: flex;
  flex-direction: column;
  gap: 8px
}

.page-header-item {
  display: grid;
  grid-template-columns: 3fr 4fr 1fr;
  align-items:center;
  gap: 20px,
}

.breadcrumb-item {
  cursor:pointer
}

.header-child-content {
  display:flex
}

.clickable {
  cursor:pointer
}

.card-content-div {
  line-height: 1.8rem;
hyphens:auto
}

.legend-color {
  width:24px;
  height:24px;
}

.cpm-milestone-green {
  background:#46905e
}


.cpm-milestone-orange {
  background:#9c802b
}


.cpm-milestone-red {
  background:rgb(226 14 14 / 79%)
}

.cpm-milestone-blue {
  background:#4779df
}


.cpm-milestone-purple {
  background:#b97777
}

.legend-container {
  display: flex;
  flex-direction: column;
  gap:8px
}

.legend-item {
  display: grid;
grid-template-columns: 10% 90%;
align-items: center;
font-size: 16px;
}

.flex {
  display: flex;
  align-items: center;
  gap:4px
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap:16px
}

.right-align-flex-container {
  margin-left:auto
}
.cpm-milestone-item-card .C-Popover__content {
  margin-right:0px
}

.C-DatePicker__Dropdown__YearSelect .C-DropdownMenu {
  left: -60px
}

.darkModeToggleLabel {
  color:var(--canvas-theme-color--text);
  display: block;
  line-height: var(--canvas-line-height--dense);
  margin-bottom: 6px;
  transition: canvas-transition(color);
}

.disable-click {
  pointer-events: none;
}

.align {
  display: flex;
  align-items: center;
}

.loading-container {
  position:relative;
  opacity:0.5;
  pointer-events: none;
}


.positioned-loader {
  position:absolute;
  top:50%;
  left:50%;
}

.centered-with-margin {
  margin:0 auto
}


.single-file-to-upload-row-container {
  display: flex;
  width: 100%;
  align-items: center;
  gap:10%
}

.file-upload-name-with-ellipsis{
  display: inline-block;
  overflow: hidden;
  max-width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file-upload-name-with-ellipsis-inner{
  overflow: hidden;
  text-overflow: ellipsis;
}

.single-file-to-upload-row-actions {
margin-left:auto;
display:flex;
// align-items: center;
gap:8px
}

.file-name-edit-dialog-div {
 display: flex;
 align-items: center;
 gap:4px
}

.dialog-file-name-input {
  width:80%
}

// Fix for iphone zooming on input focus
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: var(--canvas-font-size--md) !important
}

.file-validation-messages-toaster-div {
  display: flex;
  flex-direction:column;
  gap: 12px;
  margin-top:8px
}

.clickable {
  cursor:pointer
}

.centered-flex {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.white-color {
  color:#ffffff
}

.inline {
  display: inline;
}

.cpm-mobile-root-inner .C-Toaster .C-GridContainer {
  width:100vw
}

.cpm-milestone-pages-card {
  margin:0 -16px
}

.image-full-screen-header {
  background:var(--canvas-theme-color--background)
}


.center-fit {
  padding: 0;
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
}

.file-name-edit-dialog p {
  padding:3px
}

.milestone-status {
  padding: 5px;
  border: solid;
  border-radius: 25px;
  font-weight: bold;
  font-size: large;
  max-width: fit-content;
}

